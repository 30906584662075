<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name"/>
    <v-row>
      <v-col>
        <base-table ref="base_table" :page="page" @edit="edit"/>
      </v-col>
    </v-row>
    <edit-note ref="edit_note" modal_name="edit_note" :module_name="page.name" @document_preview="preview" @refresh="reload"/>
    <document-edit ref="document_edit" modal_name="document_edit"/>
  </v-container>
</template>

<script>
import BaseTable from "../../components/commonComponents/BaseTable";
import EditNote from "./Edit";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import DocumentEdit from "../documents/Edit";
export default {
  components: {
    DocumentEdit,
    BaseTable,
    BaseBreadcrumb,
    EditNote,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("notes"),
        name: "notes",
        model: "note",
      },
    };
  },
  methods: {
    edit(item) {
      this.$refs.edit_note.load(item.id);
    },
    reload() {
      this.$refs.base_table.run();
    },
    preview(file) {
      this.$refs.document_edit.openModal('document_edit');
      this.$refs.document_edit.load(file);
    },
  },
};
</script>
